import styled from "styled-components";

export const ProjetosStyle = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(0, #120c56, #000000);

  h3{
    color: white;
   text-align: center;
    font-weight: 100;
    font-size: 1.5em;
  }
`;
